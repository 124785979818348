<template>
    <div class="d-flex flex-column align-items-start">
        <div class="d-flex mb-4">
            <v-chip class="px-5 mr-2"
                    style="font-weight: 500; color: white" color="#269D4F">{{news.classification}}</v-chip>
            <v-chip v-if="hasSource"
                    class="px-5"
                    style="font-weight: 500; color: white" color="#444444">{{source}}</v-chip>
        </div>

        <h2>{{news.title}}</h2>

        <strong>{{getDateSource}}</strong>

        <div v-html="getContent"></div>

        <hr class="w-100 mt-12" />
    </div>
</template>

<script>
    export default {
        name: "WeekNews",

        data: () => ({
            hasSource: true,
            source: null,
        }),

        props: {
            news: Object,
        },

        computed: {
            getDateSource: function () {
                /* eslint-disable */
                const [date, time] = new Date(this.news.dateRefined).toLocaleString().split(' ');
                const [hora, min, seg] = time.split(':')
                return date + ' às ' + hora + 'h' + min;
                /* eslint-enable */
            },

            getContent: function () {
                const content = this.news.content ? this.news.content : this.news.cleanContent;
                return content.replace(/src="\//g, `src="${this.rootSource}/`);
                
                //return content.replace("\n", "<br/>")
            }
        },

        created() {
            if (this.news.link.includes("www.stf.jus.br")) {
                this.source = "STF";
                this.rootSource = "https://www.stf.jus.br";
            }
            else if (this.news.link.includes("www.stj.jus.br")) {
                this.source = "STJ";
                this.rootSource = "https://www.stj.jus.br";
            }
            else if (this.news.link.includes("www.tst.jus.br")) {
                this.source = "TST";
                this.rootSource = "https://www.tst.jus.br";
            }
            else if (this.news.link.includes("www.camara.leg.br")) {
                this.source = "Câmara";
                this.rootSource = "https://www.camara.leg.br";
            }
            else this.hasSource = false;
        }
    };
</script>

<style>
    
</style>