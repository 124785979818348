<template>
    <div class="w-100 h-100 pl-0 pl-md-7">
        <div v-if="listNews.length === 0"
             class="d-flex justify-content-center align-items-center h-100 w-100">
            <div v-if="isSelected" class="d-flex flex-column align-items-center">
                <v-icon large
                        class="mb-3 mx-auto"
                        color="#9EDAB0"
                        style="font-size: 80px">mdi-emoticon-sad-outline </v-icon>

                <h3 class="text-center"
                    id="caption-informes">
                    Nenhuma notícia obtida
                </h3>
            </div>

            <div v-else
                 class="d-flex flex-column align-items-center">
                <v-icon class="mb-3"
                        color="#5CBE7D"
                        style="font-size: 80px">mdi-newspaper</v-icon>

                <h3 class="text-center"
                    id="caption-informes"
                    v-html="text"></h3>
            </div>
        </div>

        <div v-else
                class="d-flex flex-column pl-lg-7"
                id="container-weeknews">
            <div class="d-flex flex-column pl-lg-7"
                    id="container-weeknews">
                <div v-for="news in listNews"
                        :key="JSON.stringify(news._id)">
                    <WeekNews :news="news" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import WeekNews from './WeekNews.vue';

    export default {
        name: "ListWeekNews",

        components: {
            WeekNews,
        },

        data: () => ({
            text: `Selecione uma semana no painel para ler as notícias daquela semana`,
        }),

        props: {
            isSelected: Boolean,
            listNews: Array,
        },

        computed: {}
    };
</script>

<style>
    #caption-informes {
        color: #5CBE7D;
        width: 40%;
        min-width: 300px
    }

    @media (max-width: 300px) {
        #caption-informes {
            width: 100%;
            min-width: 0px;
        }
    }
</style>