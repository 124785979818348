<template>
    <div class="d-flex mt-8 h-100 flex-md-row flex-column">
        <!--<DialogEmail :week="selectedWeek"></DialogEmail>-->

        <div class="d-flex" id="informes">
            <div class="w-100 h-100">
                <div class="d-flex flex-column">
                    <div class="d-flex justify-content-between" @click="opened = !opened">
                        <h2>Informes semanais</h2>
                        <v-icon large color="black" class="d-inline d-md-none">
                            {{opened ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
                        </v-icon>
                    </div>
                    <div :class="showWeeks ? 'opened' : 'closed'">
                        <vuescroll ref="vs">
                            <div class="d-flex flex-column" id="container-weekcard">
                                <a v-for="week in pastWeeks"
                                   :key="week.id"
                                   @click="getNewsFromWeek(week)">
                                    <WeekCard :week="week" :selected="selectedWeek && week.id == selectedWeek.id"/>
                                </a>
                                <div class="d-flex justify-content-center">
                                    <v-btn rounded
                                           small
                                           outlined
                                           color="#888"
                                           class="ma-4"
                                           id="loadmore"
                                           elevation="3"
                                           @click="getWeekRange()">
                                        <v-icon small class="mr-2">mdi-plus</v-icon> Carregar mais semanas
                                    </v-btn>
                                </div>
                            </div>
                        </vuescroll>
                    </div>
                </div>
            </div>
        </div>

        <div class="divider divider-news"></div>

        <ListWeekNews :listNews="listNews"
                      :isSelected="hasFetchedNews"></ListWeekNews>
    </div>
</template>

<script>
    import vuescroll from 'vuescroll';
    import axios from 'axios';

    //import DialogEmail from '../components/DialogEmail.vue'
    import WeekCard from '../components/WeekCard.vue';
    import ListWeekNews from '../components/ListWeekNews.vue';

    export default {
        name: 'Informes',

        components: {
            WeekCard,
            ListWeekNews,
            //DialogEmail,
            vuescroll,
        },

        data() {
            return ({
                weeks: [],
                listNews: [],
                pastWeeks: [],
                userPreferences: [],
                error: null,
                selectedWeek: null,
                opened: false,
                hasFetchedNews: false,
            })
        },

        methods: {
            getWeekRange: function () {
                const index = this.pastWeeks && this.pastWeeks.length;
                var today = new Date();
                const dayInMs = 86400000;
                const weekInMs = 7 * dayInMs;

                const getStartAndEnd = (item) => {
                    return {
                        id: item + index,
                        end: new Date(today.getTime() - (weekInMs * (item + index))),
                        start: new Date(today.getTime() - (weekInMs * (item + index + 1))),
                    }
                }

                this.pastWeeks = this.pastWeeks.concat([...Array(16).keys()].map(getStartAndEnd))
            },

            getUserPreferences: function () {
                const userId = this.$store.getters.authUser.id
                axios.get("/api/Home/GetUsersPreferencesFromAPI?id=" + userId)
                    .then((resp) => {
                        this.userPreferences = resp.data.map((item) => item.title)
                    })
                    .catch((err) => {
                        this.error = err;
                    })
            },

            getNewsFromWeek: function (week) {
                this.selectedWeek = week;
                
                const postBody = {
                    "ListSubjects": this.userPreferences,
                    "StartDate": week.start.toISOString(),
                    "EndDate": week.end.toISOString()
                }

                axios.post("/api/Home/GetNewsByListCategories", postBody)
                    .then((resp) => {
                        this.hasFetchedNews = true;
                        this.listNews = resp.data
                    })
            },
        },

        computed: {
            showWeeks: function () {
                return this.opened || this.$widerThan('sm', this.$vuetify.breakpoint.name);
            }
        },

        created() {
            this.getWeekRange();
            this.getUserPreferences();

            setTimeout(() => {
                let distance = 50
                let duration = distance * 10;

                this.$refs.vs.scrollTo(
                    { y: distance },
                    duration,
                    "easeInOutQuad");

                setTimeout(() => {
                    this.$refs.vs.scrollTo(
                        { y: 0 },
                        duration,
                        "easeInOutQuad");
                }, duration)
            }, 500)
        }
    }
</script>

<style>
    #informes {
        min-width: min(30vw, 350px);
    }

    .divider-news {
        background: #D9D9D9;
        margin-left: calc(var(--bs-gutter-x) * .5);
    }

    .opened {
        height: 100%;
    }

    .closed {
        height: 0;
    }

    #container-weekcard {
        max-height: min(70vh, 1000px);
        text-decoration: none;
        color: #000;
    }

    #container-weeknews {
        opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 0.3s;
        width: 100%;
        max-height: min(70vh, 1000px);
    }

    @media (max-width: 960px) {
        #informes {
            min-width: 0;
        }
    }
</style>