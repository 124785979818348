<template>
    <div :class="getClass">
        <div class="d-flex flex-column align-items-start">
            <p class="count">Semana do dia</p>
            <p class="title">{{getDateRange}}</p>
            <!--<p class="count">{{getCountNews}}</p>-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "WeekCard",

        data: () => ({}),

        props: {
            week: Object,
            selected: Boolean,
        },

        computed: {
            getDateRange: function () {
                return `${this.week.start.toLocaleDateString()} à ${this.week.end.toLocaleDateString()}`;
            },

            getCountNews: function () {
                let count = this.week.count;
                return count + (count > 1 ? ' informes coletados' : ' informe coletado');
            },

            getClass: function () {
                return 'week-card ' + (this.selected ? 'week-selected' : '');
            }
        }
    };
</script>

<style>
    .week-card {
        border-left: 1px solid #269D4F;
        padding: 10px 0 10px 17px;
        transition: 0.3s;
    }

    .week-selected {
        border-left: 5px solid #269D4F;
    }

    .week-card:hover {
        border-left: 5px solid #269D4F;
        padding-left: 21px;
    }
    
    .week-card p {
        margin: 0;
    }

    .week-card .title {
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: 700;
    }

    .week-card .count {
        font-size: 0.75rem;
        font-weight: 400;
    }
</style>